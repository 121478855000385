import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Overview`}</h2>
    <p>{`Tooltips are often used to convey information. However, tooltips are rarely appropriate, and their misuse can result in a myriad of accessibility issues.
Always consider `}<strong parentName="p">{`not`}</strong>{` using a tooltip for an improved user experience.`}</p>
    <h2>{`Why?`}</h2>
    <ul>
      <li parentName="ul">{`Tooltips are hidden by default making it easy to miss, so they should never be used to convey critical information.`}</li>
      <li parentName="ul">{`Tooltips are never accessible on mobile devices.`}</li>
      <li parentName="ul">{`Tooltips should `}<strong parentName="li">{`never`}</strong>{` be set on non-interactive elements (for example, `}<inlineCode parentName="li">{`div`}</inlineCode>{`, `}<inlineCode parentName="li">{`span`}</inlineCode>{`, `}<inlineCode parentName="li">{`p`}</inlineCode>{`), and should only ever be set on interactive elements (for example, `}<inlineCode parentName="li">{`button`}</inlineCode>{`, `}<inlineCode parentName="li">{`a`}</inlineCode>{`). Tooltips on non-interactive elements are not accessible to keyboard users and screen reader users.`}</li>
    </ul>
    <h2>{`Guidelines`}</h2>
    <h3>{`For designers`}</h3>
    <ul>
      <li parentName="ul">{`Reserve tooltips for components like icon buttons.`}</li>
      <li parentName="ul">{`Keep your tooltip text minimal.`}</li>
      <li parentName="ul">{`Only include tooltips on other components as a last resort.`}</li>
      <li parentName="ul"><strong parentName="li">{`Never`}</strong>{` include tooltips on non-interactive components (`}<inlineCode parentName="li">{`div`}</inlineCode>{`, `}<inlineCode parentName="li">{`span`}</inlineCode>{`, `}<inlineCode parentName="li">{`p`}</inlineCode>{`).`}</li>
    </ul>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img width="456" alt="Icon buttons with tooltips for edit, favorite, and sponsor" src="https://github.com/primer/primitives/assets/18661030/e9f86af8-3792-4794-af68-ad0efccd8bc8" />
    <Caption mdxType="Caption">Use a tooltip to give a text label to an icon button</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img width="456" alt="Non-interactive text element activating a long and wordy tooltip on hover" src="https://github.com/primer/primitives/assets/18661030/1d8d86a2-8ee7-40d2-8934-c3d36f34d9b3" />
    <Caption mdxType="Caption">Don't use tooltips on non-interactive elements</Caption>
  </Dont>
    </DoDontContainer>
    <Box mb={3} display="flex" alignItems="flex-start" alignItems="center" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="p" mt="0" mdxType="Box">
    <h4>Annotations</h4>If you have an appropriate tooltip in your design, annotate the tooltip carefully with the
    preferred type (whether it's a label or description) to ensure proper semantics.
  </Box>
  <img width="456" alt="Mock of an icon button and its tooltip with an annotation pointing to the tooltip with 'label' text" src="https://github.com/primer/primitives/assets/18661030/f25a684d-52f0-42aa-9e72-7aba11ffb98e" />
    </Box>
    <h3>{`For developers`}</h3>
    <p>{`If you come across a design or a page with a tooltip on a static element (for example, `}<inlineCode parentName="p">{`div`}</inlineCode>{`, `}<inlineCode parentName="p">{`span`}</inlineCode>{`, `}<inlineCode parentName="p">{`p`}</inlineCode>{`), remove it. Consult a designer for an alternative way of conveying the information.`}</p>
    <p>{`If you determine that tooltips are appropriate for your use case, make sure to carefully follow guidelines set in `}<a parentName="p" {...{
        "href": "https://primer.style/view-components/components/alpha/tooltip"
      }}>{`Primer ViewComponents: Tooltip component`}</a>{`.`}</p>
    <h2>{`Alternatives to tooltips`}</h2>
    <p>{`See `}<a parentName="p" {...{
        "href": "../../components/tooltip.mdx"
      }}>{`Tooltip interface guidelines`}</a>{` for example alternatives to using tooltips.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      